























import { Component, Vue } from 'vue-property-decorator'
import {RouteNames} from "@/router/types";
import {authService} from "@/services";
import {AccountFriendsModuleStoreModule, AuthStoreModule} from "@/store";
import {IServiceError} from "@/core/service/types";
import {NotificationService} from "@/components/Notifications/notification.service";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {DefaultRedirectRoutes} from "@/router/redirect.routes";
import {Result} from "typescript-result";
import {ICustomTokenResponse, ISignInWithProviderRequest} from "@/services/auth/types";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";

export enum AuthProvider {
  None = 'none',
  Google = 'google',
  Twitch = 'twitch',
  Discord = 'discord'
}

enum AuthStatus {
  Loading,
  Success,
  Fail
}

@Component({
  enums: {
    AuthStatus
  }
})
export default class AuthCallbackPage extends Vue {
  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AccountFriendsModuleGetterTypes.INVITATION_CODE, { namespace: StoreNamespace.AccountFriends })
  private readonly invitationCode!: string | null

  private authStatus: AuthStatus = AuthStatus.Loading
  private authCode: string = ''
  private authProvider: AuthProvider = AuthProvider.None

  private async created () {
    if (this.isLoggedIn) {
      await this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName())
      return
    }
    this.authCode = this.$route.query.code as string
    this.authProvider = this.$route.params.provider as AuthProvider

    if (!this.authCode) {
      await this.$router.replace({ name: RouteNames.AuthSignInRoute })
      return
    }

    switch (this.authProvider) {
      case AuthProvider.Google:
        const signInWithGoogleResult = await this.signInWithGoogle()
        await this.parseSignInWithProviderResult(signInWithGoogleResult)
        break;
      case AuthProvider.Twitch:
        const signInWithTwitchResult = await this.signInWithTwitch()
        await this.parseSignInWithProviderResult(signInWithTwitchResult)
        break;
      case AuthProvider.Discord:
        const signInWithDiscordResult = await this.signInWithDiscord()
        await this.parseSignInWithProviderResult(signInWithDiscordResult)
        break;
    }
  }

  private async signInWithGoogle (): Promise<Result<IServiceError, ICustomTokenResponse>> {
    const customTokenResult = await authService.signInWithGoogle(this.createSignInRequest())

    if (customTokenResult.isFailure()) {
      this.authStatus = AuthStatus.Fail
      return Result.error(customTokenResult.error)
    } else {
      this.authStatus = AuthStatus.Success
      return Result.ok(customTokenResult.value)
    }
  }

  private async signInWithTwitch (): Promise<Result<IServiceError, ICustomTokenResponse>> {
    const customTokenResult = await authService.signInWithTwitch(this.createSignInRequest())

    if (customTokenResult.isFailure()) {
      this.authStatus = AuthStatus.Fail
      return Result.error(customTokenResult.error)
    } else {
      this.authStatus = AuthStatus.Success
      return Result.ok(customTokenResult.value)
    }
  }

  private async signInWithDiscord (): Promise<Result<IServiceError, ICustomTokenResponse>> {
    const customTokenResult = await authService.signInWithDiscord(this.createSignInRequest())

    if (customTokenResult.isFailure()) {
      this.authStatus = AuthStatus.Fail
      return Result.error(customTokenResult.error)
    } else {
      this.authStatus = AuthStatus.Success
      return Result.ok(customTokenResult.value)
    }
  }

  private async parseSignInWithProviderResult (signInWithProviderResult: Result<IServiceError, ICustomTokenResponse>): Promise<void> {
    if (signInWithProviderResult.isFailure()) {
      NotificationService.showErrorNotification(signInWithProviderResult.error.message)
      await this.$router.replace(DefaultRedirectRoutes.defaultAuthRouteName())
    } else {
      AuthStoreModule.setIsNewUser(signInWithProviderResult.value.is_new_user)
      await AuthStoreModule.signInWithProvider(signInWithProviderResult.value.custom_token)
      AccountFriendsModuleStoreModule.setInvitedAccount(signInWithProviderResult.value.friend)
      NotificationService.showSuccessNotification('Successful authentication')
      await this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName())
    }
  }

  private clearLatestAuthData () {
    this.authCode = ''
    this.authProvider = AuthProvider.None
  }

  private beforeDestroy () {
    this.clearLatestAuthData()
  }

  private createSignInRequest (): ISignInWithProviderRequest {
    if (this.invitationCode) {
      return {
        code: this.authCode,
        invitation_code: this.invitationCode
      }
    }

    return {
      code: this.authCode
    }
  }
}
