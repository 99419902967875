


































import { Component, Vue } from 'vue-property-decorator'
import {RouteNames} from "@/router/types";
import SignWithDiscord from "@/components/Auth/SignWithDiscord.vue";
import SignWithTwitch from "@/components/Auth/SignWithTwitch.vue";
import SignWithGoogle from "@/components/Auth/SignWithGoogle.vue";
import SignInForm from "@/components/Auth/SignInForm.vue";
import InvitationCodeInfo from "@/components/Friends/InvitationCodeInfo.vue"
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";

@Component({
  components: {
    ResponsiveContainer,
    SignInForm,
    SignWithGoogle,
    SignWithTwitch,
    SignWithDiscord,
    InvitationCodeInfo
  },
  enums: {
    RouteNames
  }
})
export default class SignInPage extends Vue {
  @Getter(AuthModuleGetterTypes.IS_AUTH_PROCESS_STARTED, { namespace: StoreNamespace.Auth })
  private readonly isAuthProcessStarted!: boolean
}
