var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"authValidationObserver",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmitAction)}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"Email","disabled":_vm.isAuthProcessStarted,"outlined":"","dense":"","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Password","disabled":_vm.isAuthProcessStarted,"outlined":"","dense":"","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","type":"submit","color":"primary","disabled":invalid || _vm.isAuthProcessStarted,"loading":_vm.isAuthProcessStarted}},[_vm._v("Sign In")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }